.App {
  text-align: center;
  margin: auto;
  position: relative;
  width: 100%;
}

.input-data {
  padding: 0rem 12rem;
}

.input-text {
  padding: 0rem 12rem;
}

.buttons {
  padding: 0rem 12rem;
}

.header {
  padding: 0rem 12rem;
  display: flex;
  align-items: center;
  margin: 1.5rem 0
}

.products {
  padding: 0rem 12rem;
}
